@import 'styles/variables';

.section {
  display: flex;
  flex-direction: column;

  .group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-top: 15px;

    .block {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }
  }
}