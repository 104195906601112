@import 'styles/variables';

:export {
  widthThreshold: $widthThreshold;
}

.layerMask {
  z-index: 110;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  background-color: $layerMaskColour;
  transition: $defaultTransitionTime;
}
