@import 'styles/variables';

.codeBlock {
    position: relative;
    margin-bottom: 30px;

    button {
        border: 1px solid grey !important;
    }

    .langSelect {
        margin: 0 !important;
        width: 20px;
        height: 20px;
        display: none;

        @media screen and (min-width: 940px) {
            display: inline-block;
        }
    }

    .lang {
        padding: 5px 10px;
        border-radius: 5px;
        box-sizing: border-box;
        background: var(--theme-primary-nav);
        position: absolute;
        top: 0;
        left: -65px;
        cursor: pointer;
    
        p {
            display: inline-block;
            margin: 0 0 0 5px;
            vertical-align: middle;
        }

        .langSelectArrow {
            width: 10px;
            height: auto;
            transition: .2s;
            display: inline-block;
            margin: 0 0 0 5px;
            transform: rotate(180deg);

            &.active {
                transform: rotate(0deg);
            }
        }
    }

    .langList {
        position: absolute;
        top: 40px;
        left: -50px;
        padding: 0;
        overflow: hidden;
        height: 0;
        transition: .2s;

        & + div {
            min-height: 45px;
        }

        &.active {
            height: auto;
        }

        li {
            background: var(--theme-primary-nav);
            padding: 5px 10px;
            list-style: none;
            margin: 0;
            cursor: pointer;
            transition: .2s;

            &:hover {
                background: rgb(192, 192, 192);
            }
        }

        li:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        li:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}