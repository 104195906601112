@import 'styles/variables';

.container {

  .options {
    margin-top: 3 * $defaultSpacing;
    padding: 0;

    li {
      list-style: none;
      display: flex;
      margin-bottom: 2 * $defaultSpacing !important;

      a {
        padding: 0;
      }
    }
  }

  .optionIcon {
    background: transparent;
  }

  .linkIcon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    background: transparentize($support-e, 0.6);

    img {
      width: 60%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto !important;
    }
  }

  .linkContainer {
    margin-left: 20px;
    margin-top: 7px;
    width: calc(100% - 80px);
  }
}
