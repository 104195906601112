@import 'styles/variables';

.content {
    .open {
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-end;
        // align-items: center;
        .colab {
            position: relative;
            width: 50px;
            background-color: transparent !important;
        }
    }
}
