@import 'styles/variables';

:export {
  topNavHeight: $topNavHeight;
}

.topNav {
  z-index: 200;
  position: fixed;
  height: $topNavHeight;

  .nav {
    z-index: 999;
    box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 10%), 0px 2px 1px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%);
    background: var(--theme-primary);
    padding: 10px 5px;
    transition: $defaultTransitionTime;

    a {
      font-weight: $navBarWeight;
    }

    .navIcon {
      position: relative;
      padding-top: 4px;
      transition-duration: 0.2s, 0.2s, 0.6s;
      transition-property: transform, opacity, margin-left;

      img {
        width: 20px;
        color: white; // take from global css
        pointer-events: none;
        user-select: none;
      }
    }

    .brand {
      margin-left: 20px;
      img {
        width: 160px;
      }
    }
  }
}

// @media only screen and (max-width: $widthThreshold) {
//   .topNav {
//     height: $collapedtopNavHeight;
//   }
// }
