@import 'styles/variables';

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 15px;
    transition: $defaultTransitionTime;
    margin: 0 3px;

    &.hidden {
      opacity: 0.2;
    }

    &.visible {
      opacity: 1;
    }
  }

  .themeBtn {
    text-align: center;
    pointer-events: none;
  }
}
