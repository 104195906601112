@import 'styles/variables';

.section {

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .list {
    list-style-type: none;
  }

  .bulletList {
    list-style-type: disc;
  }

  .addrsBlock {
    display: flex;
    flex-direction: column;
  }
}
