@import 'styles/variables';

.container {
    .infoP {
        margin-bottom: 0;
        font-size: 16px;
    }

    a {
        padding: 0;
        display: inline;
    }
}
