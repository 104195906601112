@import 'styles/variables';

.hero {
  width: 100%;
  position: relative;
  z-index: 2;
  // margin-top: 100px;
}

// .heroTop {
//   margin-top: 0;
//   transition: .5s;
// }

.section {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 50%;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .leftImg {
    margin-right: 25px;
  }

  .rightImg {
    margin-left: 25px;
  }

  .subSection {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .link {
      color: $secondary;

      &:hover {
        color: $shockPink;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $widthThreshold) {
  .subSection {
    width: 60% !important;
  }

  .section {
    img {
      width: 40% !important;
    }
  }
}
