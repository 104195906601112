@import 'styles/variables';

.Btn {
  color: var(--theme-text-colour);
  background-color: var(--theme-secondary);
  border: var(--theme-secondary);

  &:hover {
    color: var(--theme-text-colour);
    background-color: $shockPink;
  }
}

.Disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}
