@import 'styles/variables';

.searchable-list {

    & li {
        list-style-type: none;
    }

    & > ul {
        padding-left: 0;
    }

    & > li {
        margin: 0;
        padding: 0;
    }
}

.searchable-list-input-container {
    position: static;
    padding: 5px 5px 5px 5px;
    left: 0;
    z-index: 1;
}

.searchable-list-input-fixed-container {
    position: fixed;
    padding: 5px 5px 5px 5px;
    top: $topNavHeight;
    right: 22%;
    border-radius: 3px;
    z-index: 1;
    transition: background-color 0.2s ease-in 0.2s;

    @media only screen and (max-width: $widthThreshold) { 
        right: auto;
        left: auto;
        width: 90%;
    }
}

.searchable-list-input {
    border: 1px solid var(--theme-text-colour);
    border-radius: 3px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0px 0px 5px black;

}

.searchable-item-card {
    margin-left: 0;
    padding: 15px 0 20px 0;
    list-style-type: none;

    & > li {
        line-height: 26px;
    }

    & > li:last-child {
        padding: 20px 10px 10px 10px;
    }
}
