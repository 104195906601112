@import 'styles/variables';

.container {
    margin-bottom: 80px;

    .infoP {
        margin-bottom: 0;
        font-size: 16px;
    }

    a {
        padding: 0;
        display: inline;
    }

    pre {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    small {
        font-size: 0.8rem;
        width: 90%;
        display: block;
        font-style: italic;
        max-width: 363px;
        margin: 0 auto 20px;
    }
}

.qrExample {
    width: 200px;
    display: block;
    margin: 10px auto;
}