@import 'styles/variables';

.container {
    margin-top: 10px;
    transition: 0.5s;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 10px;

    &.hidden {
        opacity: 0;
        pointer-events: none;
        transform: scale(0);
        margin-left: -40px;
    }

    span {
        display: block;
        width: 24px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: white;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease;
    }

    span:first-child {
        transform-origin: 0% 0%;
    }

    span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    &:global(.active) {
        pointer-events: all;
        span {
            opacity: 1;
            transform: rotate(45deg) translate(3px, -7px);
        }
        
        span:nth-child(2) {
            transform: rotate(-45deg) translate(1px, 10px);
        }

        span:nth-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }
    }
}