@import 'styles/variables';

h1, h2, h3 {
  scroll-margin-top: $topNavHeight + $defaultSpacing;
}

/* Safari-only */
@supports (-webkit-hyphens:none) {
  h1, h2, h3 {
      padding-top: $topNavHeight + $defaultSpacing;
      margin-top: -($topNavHeight + $defaultSpacing);
  }
}