@import 'styles/global.scss';
@import 'styles/variables';

:export {
  widthThreshold: $widthThreshold;
}

.app {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: var(--theme-background);
  color: var(--theme-text-colour);
  transition: 0.3s;
  overflow: hidden;

  .mainContainer {
    z-index: unset;
    position: relative;
    width: 100vw;
    min-height: 100%;
    height: auto !important;

    .sideContainer {
      position: relative;
      height: 100%;
      transition: $defaultTransitionTime;

      .contentContainer {
        padding-right: 18vw;
        height: auto;
        transition: $defaultTransitionTime;

        .content {
          min-height: 100vh;
          padding: 80px 3rem 2rem;
          max-width: 55em;
          margin: 0 auto;
        }
      }
    }
  }


  pre {
    background: var(--theme-primary-nav);
    padding: 25px;
    box-sizing: border-box;
    border-radius: 10px;
  }


  // Globals on elements
  a {
    color: var(--theme-link-colour);
    text-decoration: none;
    font-weight: 400;

    &:hover {
      color: $shockPink;
    }
  }

  h1 {
    margin-top: 30px;
  }

  img {
    max-width: 100%;
  }

  p {
    line-height: 24px;
    margin-bottom: 20px;
    text-justify: distribute;
    text-align: justify;
    word-spacing: -2px;

    code {
      padding: 5px 7px;
      background: $primaryLight;
      border-radius: 5px;
    }
  }

  :global(.jupyter-viewer) {
    pre {
      background: transparent;
    }
  }

  small {
    display: block;
    width: 50%;
    margin: 15px auto;
    font-style: italic;
    text-align: center;
  }
}

.content {
  ul {
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: $widthThreshold) {
  .app {
    .contentContainer {
      padding-right: 0 !important;
      height: auto;
      transition: $defaultTransitionTime;

      .content {
        padding: 80px 1rem 1rem !important;
      }
    }

    p {
      margin-bottom: 20px;
      text-align: justify;
      word-spacing: -2px;
  
      code {
        padding: 5px 7px;
        background: $primaryLight;
        border-radius: 5px;
      }
    }
  
    
  }
}

:global .post-button,
:global .get-button {
  display: inline-block;
  padding: 5px 8px;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

:global .post-button {
  background: #ff6600;
}

:global .get-button {
  background: #2ba2f2;
}

:global .mdx-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 25px 0 35px;

  th, td {
    padding: 5px;
    border: 1px solid darken($mid-text-colour, 25%);
  }

  th {
    background: darken($mid-text-colour, 15%);
  }
}