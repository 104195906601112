@import 'styles/variables';

.container {
  z-index: 100;
  display: flex;
  bottom: 0;
  width: 100%;
  background: var(--theme-primary);
  // background-color: #F2F2F2;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px #0000, 0px 1px 10px 0px #0000;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .content {
    width: 100%;
    margin-top: 40px;
    // border: dotted red 1px;
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: space-evenly;
    flex-direction: row;

    .col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.social {
        width: 80px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        z-index: 11;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;

          @media only screen and (min-width: $widthThreshold) {
            &:nth-child(odd) {
              margin-left: 5px;
            }
          }
        }

        .link {
          width: 100%;
          margin-top: 2px;
          text-align: center;
        }
      }

      img {
        width: 30px;
        height: auto;

        transition: all .2s;
        transform-origin: 50% 50%;

        &:hover {
          transform: scale(1.2);
          cursor: pointer;

        }
      }

      .title {
        font-weight: bold;
        color: $light-text-colour;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-top: auto; // align-self: flex-end not working for some reason
    padding: 50px 0 0;
    margin-bottom: 30px;

    span {
      color: white;
      margin-left: 25px;
    }

    img {
      width: 100px;
      height: auto;
    }
  }
}

@media only screen and (max-width: $widthThreshold) {
  .content {
    font-size: small;

    .col {
      margin-left: 5px;
      margin-right: 5px;
    }

    .icon {
      width: 50px;
      height: auto;
    }
  }
}