@import 'styles/variables';

.container {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;

    &:global(.info) {
        background: var(--theme-note);
    }
}