@import 'styles/variables';

.container {
    margin-bottom: 80px; 

    .infoP {
        margin-bottom: 0;
        font-size: 16px;
    }

    a {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    .options {
        padding: 0;
        margin-bottom: 0;

        .disabled {
            opacity: .5;
            pointer-events: none;
            cursor: not-allowed;

            p {
                opacity: .5;
                text-align: center;
                // color: var(--theme-on-surface);
            }
        }

        li {
            list-style: none;
            display: inline-block;
        
            &:not(:first-child) {
                margin-left: 2 * $defaultSpacing;
            }
        }

        img {
            display: inline-block;
            width: 80px;
            height: 80px;
            margin-bottom: $defaultSpacing;
        }

        a {
            padding: 0;
            margin: 0;
        }
    }

    .generalList li {
        margin-bottom: 20px;
    }

    .js {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}