.container {
  position: relative;
  width: 100%;
  height: 50px;

  .prev {
    position: absolute;
    left: 0px;
  }

  .next {
    position: absolute;
    right: 0px;
  }
}
