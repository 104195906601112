@import 'styles/variables';

.container {
  width: 100%;
  height: 100%;
  padding-bottom: $defaultSpacing;

  img {
    width: 20px;
    height: 20px;
  }

  .pdfActionMenu {
    display: flex;
    width: 100%;
    min-height: 50px;
    height: auto;
    justify-content: space-between;
    align-items: center;

    .pageCount {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        margin-left: 2px;
      }

      // width: 100px;
    }
  }

  .dropdownMenu {
    max-height: 330px;
    overflow-y: auto;
  }  

  .page {
    margin-bottom: 5px;
  }

  .prevPage {
    position: fixed !important;
    z-index: 1;
  }

  .leftOverlay {
    position: fixed;
    border-radius: 100px;
    z-index: 180;
    background-color: white;
    width: 100px;
    height: 100px;
    left: -50px;
    top: 50vh;
    opacity: 0.2;
    transition: $defaultTransitionTime;

    img {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 25px;
      top: 40px;
      transform: rotate(-90deg);
      background: transparent;
    }

    &:hover {
      opacity: 0.5;
      box-shadow: 5px 0px 5px rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }

  .rightOverlay {
    position: fixed;
    border-radius: 100px;
    z-index: 180;
    background-color: lightgray;
    width: 100px;
    height: 100px;
    right: -50px;
    top: 50vh;
    opacity: 0.2;
    transition: $defaultTransitionTime;

    img {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 25px;
      top: 40px;
      transform: rotate(90deg);
      background: transparent;
    }

    &:hover {
      opacity: 0.5;
      box-shadow: -5px 0px 5px rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }

  .upBtn {
    position: fixed;
    z-index: 180;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 20px;
    background-color: var(--theme-primary);
    opacity: .5;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    img {
      width: 15px;
      height: 15px;
      background: transparent;
    }
  }
}

:global .react-pdf__Document {
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.07);
}

// Used to correct a display bug caused by react-pdf. annotations is offset and creates a gap between page 1 and 2
:global .react-pdf__Page__annotations.annotationLayer {
  display: none;
}
