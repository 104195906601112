@import 'styles/variables';

:export {
  sideNavWidth: $sideNavWidth; // Comes from global.scss
  widthThreshold: $widthThreshold;
}

.sideNav {
  position: fixed;
  z-index: 120;
  height: calc(100% - $topNavHeight);
  width: $sideNavWidth;
  top: $topNavHeight;
  left: -$sideNavWidth;
  text-align: left;
  background-color: var(--theme-primary-nav);
  box-shadow: 1px 0 0.1em rgb(0 0 0 / 30%);
  overflow-x: hidden;
  overflow-y: auto;
  transition: $defaultTransitionTime;

  .accordionContainer {
    ul,
    li {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0px;
    }

    li.no_selection {
      user-select: none; /* standard syntax */
      -webkit-user-select: none; /* webkit (safari, chrome) browsers */
      -moz-user-select: none; /* mozilla browsers */
      -khtml-user-select: none; /* webkit (konqueror) browsers */
      -ms-user-select: none; /* IE10+ */
    }

    .mainList {
      margin: 0;
      padding-left: 0;

      .mainItem {
        .navLink {
          display: flex;
          padding: 12px;
          margin: 0;
          font-weight: 400;
          color: var(--text);
          transition: $defaultTransitionTime;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;

          &:hover {
            color: $light-text-colour;
            background-color: $support-a;
            cursor: pointer;
          }

          .badge {
            margin-left: 20px;
            font-style: italic;
            text-transform: uppercase;
            font-size: x-small;
          }

          .dropdownArrow {
            display: inline-block;
            position: absolute;
            width: 25px;
            height: 25px;
            right: 10px;

            img {
              transition: $defaultTransitionTime;
              transform: rotate(0deg);

              &.visible {
                transform: rotate(90deg);
              }
            }
          }
        }

        .active {
          background-color: $support-a;
          color: $light-text-colour;
        }

        .collapsed {
          display: none;
        }
      }
    }
  }
}

.disabled {
  text-decoration: line-through;
  cursor: not-allowed;
}
