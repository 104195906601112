@import "styles/global.scss";
@import "styles/_variables";

.editor-container {
  width: 100%;
  display: flex;
  height: 300px;
  background-color: black;
  max-width: 1200px;
  font-family: $firaCode;
  line-height: 1.5;
}

.step-highlight {
  position: absolute;
  background: #FFEC31;
  color: white;
  z-index: 200;
}

.error-highlight {
  position: absolute;
  border-bottom: 2px solid $shockPink;
  z-index: 20;
}

.code-editor {
  flex-grow: 35;
  font-weight: bold;
  padding: 6px;
  border: 0;
  color: #356480;
  border-radius: 3px 0 0 3px;
  height: 100%;
  background-color: #e0e6e9;
  resize: none;
}

.intermediate-results {
  flex-grow: 13;
  font-weight: normal;
  padding: 6px;
  text-align: center;
  color: #426c85;
  height: 100%;
  background-color: #c0c6c9;
}

.stack-state {
  flex: 34;
  font-weight: normal;
  padding: 6px;
  text-align: center;
  height: 100%;
  border-radius: 0 3px 3px 0;
  background-color: #d0d6d9;
}

.stack-errors {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  height: 15%;
  font-family: $firaSans;
  text-align: left;
  padding-left: 20pt;
  color: $shockPink;
}

.stack-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  height: 85%;
}

.stack-item-normal {
  width: 100%;
  margin-top: 4px;
  flex: 0 0 auto;
  word-break: break-all;
  overflow: auto;
  border-radius: 3px;
  background-color: #445561;
  border: 1px solid #354a58;
}

.stack-item-result {
  width: 100%;
  margin-top: 4px;
  flex: 0 0 auto;
  word-break: break-all;
  overflow: auto;
  border-radius: 3px;
  background-color: #d48a57;
  border: 1px solid #d48550;
}

.editor-dropdown-menu-container {
  margin-bottom: 6px;
  margin-left: 6px;
  display: inline-block;

  a {
    color: #e0e6e9;
  }
  .editor-dropdown-menu {
    background-color: #1a282f;
    color: #e0e6e9;

    .editor-dropdown-menu-item {
      &:hover {
        background-color: #2a383f;
        color: #fff;
      }
      &:global(.active), &:active {
        background-color: #3a5262 !important;
      }
    }
  }
  strong {
    color: #e0e6e9;
  }
}

.editor-dropdown-menu-container button,
.editor-button {
  background-color: #2a4252 !important;
  border: 2px #233d4d solid !important;
  color: #d0d6d9 !important;
  padding-left: 30px;
  padding-right: 30px;
  &:focus {
    background-color: #3a5262;
    box-shadow: 0 0 0 2px #233d4d !important;
    border: 2px #334d5d solid;
    color: #e0e6e9;
  }
  &:hover {
    background-color: #445561;
    border: 2px #354a58 solid;
    color: #e0e6e9;
  }
  &:active {
    background-color: #225475;
    border: 2px #354a58 solid;
    color: #fff;
  }
}
