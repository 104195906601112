@import 'styles/variables';

.content {
  .linkCard {
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--theme-text-colour);
    background-color: var(--theme-card-background);
    transition: $defaultTransitionTime;
    box-shadow: 0px 0px 0px 0px var(--contrast);

    &:hover {
      box-shadow: 0px 0px 10px 5px var(--contrast);
      cursor: pointer;
    }

    .title {
      display: inline;
    }

    .link {
      display: inline;
      font-style: italic;
    }

    p {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .signature {
      margin-bottom: 10px;
    }
  }
}