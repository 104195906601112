@import 'styles/variables';

// .tocContainer {
//   position: relative;
//   width: auto;
//   overflow: auto;
// }


.tocContainer {
  width: 18vw;
  position: fixed;
  top: 76px;
  right: 0;
  bottom: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
}

.headings {
  border-left: solid 1px #ccc;
  

  .first {
    border-bottom: 1px solid #ccc;
    font-size: 12pt;
  }
  
  li {
    list-style: none;
    margin-bottom: 5px !important;
    font-size: 11pt;
  }
  
  ul {
    margin-top: 5px;
    padding-left: 15px;
  }
}

.hidden {
  width: 0px !important;
  opacity: 0;

  transition: .5s;
}

.visible {
  width: 20% !important;
  opacity: 1;

  transition: .5s;
}

.active {
  color: $cta !important;
}

@media only screen and (max-width: $widthThreshold) {
  .tocContainer {
    display: none;
  }
}