@import 'styles/variables';

.langContainer {
  min-width: 120px;
  // padding: 10px;
  color: $light-text-colour;
  font-weight: $navBarWeight;
  cursor: pointer;
}

.flagMain {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;

  img {
    border-radius: 3px;
  }
}

.dropdownArrow {
  position: inherit;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 15px;
  right: 10px;
  margin-left: 5px;
  img {
    transition: 0.2s;
    transform: rotate(180deg);

    &.visible {
      transform: rotate(0);
    }
  }
}

.langSelection {
  position: absolute;
  width: 120px;
  padding: 0 !important;
  margin-top: 10px;
  margin-left: -15px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.65);
  background-color: var(--theme-primary);
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  li {
    display: block;
    width: 100%;
    padding: 10px 15px;
    margin: 0;
    border-radius: 0;
    list-style: none;
    box-sizing: border-box;

    &:hover {
      background: $support-a;
    }

    &:active {
      background: $support-a;
      color: $light-text-colour;
    }
  }
}
