.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    background-color: transparent !important;
  }
}
