@import 'variables';

// Page globals
:global html {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

:global body {
  // min-width: 50vw;
  min-width: 60%;
  width: 100vw;
  height: auto;
  margin: 0;
  padding: 0;
  font-family: $firaSans;
  font-weight: $defaultWeight;
  overflow: hidden;
}

:global h1 {
  font-style: normal;
  font-weight: $titleWeight;
  font-size: 40px;
  line-height: 78px;
}

:global h2 {
  font-style: normal;
  font-weight: $titleWeight;
  font-size: 30px;
  line-height: 48px;
}

:global h3 {
  font-style: normal;
  font-weight: $titleWeight;
  font-size: 25px;
  line-height: 38px;
}

:global p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

:global #root {
  height: 100%;
}

// Custom navBar
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  // border-radius: 10px;
  background-color: $primaryLight;
}

::-webkit-scrollbar-thumb {
  background: $support-a;
  border-radius: 3px;
}

@media only screen and (max-width: $widthThreshold) {
  :global h1 {
    font-style: normal;
    font-weight: $titleWeight;
    font-size: 30px;
    line-height: 38px;
  }

  :global h2 {
    font-style: normal;
    font-weight: $titleWeight;
    font-size: 20px;
    line-height: 48px;
  }

  :global h3 {
    font-style: normal;
    font-weight: $titleWeight;
    font-size: 18px;
    line-height: 38px;
  }

  :global p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
  }
}
