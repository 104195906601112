@import '../variables';

$--theme-primary: --theme-primary;
$--theme-primary-nav: --theme-primary-nav;
$--theme-secondary: --theme-secondary;
$--theme-surface: --theme-surface;
$--theme-background: --theme-background;
$--theme-on-primary: --theme-on-primary;
$--theme-on-secondary: --theme-on-secondary;
$--theme-on-surface: --theme-on-surface;
$--theme-text-colour: --theme-text-colour;
$--theme-text-colour-support: --theme-text-colour-support;
$--theme-link-colour: --theme-link-colour;
$--theme-img-background: --theme-img-background;
$--theme-card-background: --theme-card-background;
$--theme-support: --theme-support;
$--text: --text;
$--contrast: --contrast;
$--theme-note: --theme-note;

// Default theme
$theme-map-light: (
    $--theme-primary: lighten($primary, 5%),
    $--theme-primary-nav: $primaryLight,
    $--theme-secondary: $secondary,
    $--theme-surface: lighten($primary, 10%),
    $--theme-background: #fff,
    $--theme-on-primary: #fff,
    $--theme-on-secondary: #fff,
    $--theme-on-surface: #000,
    $--theme-text-colour: $support-d,
    $--theme-text-colour-support: transparentize($primary, .6),
    $--theme-link-colour:$support-a,
    $--text: $dark-text-colour,
    $--theme-img-background: #fff,
    $--theme-card-background: rgb(240, 240, 240),
    $--contrast: rgba(0, 0, 0, .1),
    $--theme-note: #bfe5ff
);

// Override the default light theme
$theme-map-dark: (
    $--theme-primary: $primary,
    $--theme-primary-nav: lighten($primary, 10%),
    $--theme-secondary: $teal,
    $--theme-surface: #2f2b2b,
    $--theme-background: darken($primary, 5%),
    $--theme-on-primary: #443e42,
    $--theme-on-secondary: #000,
    $--theme-on-surface: #f5f1f1,
    $--theme-text-colour: $light-text-colour,
    $--theme-text-colour-support: transparentize($light-text-colour, .6),
    $--theme-link-colour: $support-e,
    $--theme-img-background: #fff,
    $--theme-card-background: $primary,
    $--contrast: rgba(255, 255, 255, .1),
    $--theme-note: lighten($primary, 10%)
);